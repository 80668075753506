// 引入element-ui的Message组件
import { Message } from "element-ui";

// 自定义消息提示组件
function Messages(message,type ) {
  let msg = Message({
    type: type,
    message: message,
    // duration: 3000
  });

  setTimeout(() => {
    msg.close();
  }, 3000);
}


// 导出自定义消息提示组件
export default Messages;
