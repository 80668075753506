<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { init } from '@/common/publicUrl.js'
export default {

  name: 'App',
  components: {},
  data() {
    return {
      beginTime: 0, //开始时间
      differTime: 0, //时间差
    }
  },
  mounted() {
    init()
    window.addEventListener("beforeunload", () => this.beforeunloadHandler())
    window.addEventListener("unload", () => this.unloadHandler())
    // this.forbidZoom() //禁止缩放
  },

  destroyed() {
    window.removeEventListener("beforeunload", () => this.beforeunloadHandler())
    window.removeEventListener("unload", () => this.unloadHandler())
  },

  methods: {
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime()
    },
    unloadHandler() {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime
      if (this.gapTime <= 5) { //判断是窗口关闭还是刷新，小于5代表关闭，否则就是刷新。
        console.log('关闭了浏览器')
        var storage = window.localStorage;
        // storage.clear()
        storage.removeItem('17173')
        storage.removeItem('9i173')
      }
    },

  }
  // beforeunload事件在当页面卸载（关闭）或刷新时调用，事件触发的时候弹出一个有确定和取消的对话框，确定则离开页面，取消则继续待在本页；

  // 以下操作触发beforeunload，onbeforeunload
  // 1 ·关闭浏览器窗口 
  // 2·通过地址栏或收藏夹前往其他页面的时候 
  // 3·点击返回，前进，刷新，主页其中一个的时候 
  // 4·点击 一个前往其他页面的url连接的时候 
  // 5·调用以下事件的时候：click,document.write()方法（输出内容），document.open()
  // 6·当用window open打开一个页面，并把本页的window的名字传给要打开的页面的时候。
  // 7·重新赋予location.href的值的时候。
  // 8·通过input type=”submit”按钮提交一个具有指定action的表单的时候。


}
</script>
