import axios from "axios";
let publicUrl = {
  //loginPage:'https://wx.qtthtech.com/#/home',
  loginPage: "/",
  shopHomeUrl: "http://shop-wx.qtthtech.com/#/shop",
  shopBlindBoxUrl: "https://shop-wx.qtthtech.com/#/shop/blindBox",
  shopIntegralUrl: "https://shop-wx.qtthtech.com/#/shop?type=integralArea",
  shopWarehouseUrl: "https://shop-wx.qtthtech.com/#/shop/personWarehouse",
  emotion: "https://download-wx.qtthtech.com/shop/web/emotion/",
};
const init = () => {
  const baseUrl = axios.defaults.baseURL;
  if (baseUrl.indexOf("//api-wx") !== -1) {
  } else {
    (publicUrl.shopHomeUrl = "http://testshop-wx.qtthtech.com/#/shop"),
      (publicUrl.shopBlindBoxUrl =
        "http://testshop-wx.qtthtech.com/#/shop/blindBox"),
      (publicUrl.shopIntegralUrl =
        "http://testshop-wx.qtthtech.com/#/shop?type=integralArea"),
      (publicUrl.shopWarehouseUrl =
        "http://testshop-wx.qtthtech.com/#/shop/personWarehouse");
  }
};
export { publicUrl, init };
