// 初始化Vuex
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 全局状态
    count: 0
  },
  mutations: {
    // 全局状态修改方法
    alterLocation(state,item) {
        console.log("修改location",item);
      state.count = item
    }
  }
})


export default store